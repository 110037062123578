<template>
  <div class="brandStoriesListFollows">
    <LoadingOverlay v-show="fetchingList" />
    <div class="messageAboutFollow">{{listFeedbackMessage}}</div>
    <masonry :cols="{default: 4, 992: 2, 1200: 3}" :gutter="{default: '15px'}">
      <BrandStoryTile
        v-for="(brandStory, index) in this.brandStories"
        :key="'brandStoryTile' + brandStory.id"
        :brandStory="brandStory"
        :index="index"
      />
    </masonry>
  </div>
</template>

<script>
import BrandStoryTile from './BrandStoryTile.vue';
import LoadingOverlay from '../../../loaders/LoadingOverlay.vue';

import followedBrandStoryCopy from '../../../../data/followedBrandStoryCopy';

export default {
  name: 'BrandStoriesListFollows',
  components: {
    BrandStoryTile,
    LoadingOverlay,
  },
  data() {
    return {
      copy: followedBrandStoryCopy,
      fetchingList: false,
    };
  },
  async created() {
    if (this.brandStories.length === 0) {
      this.fetchingList = true;
    }
    await this.$store.dispatch('userFollowStore/getBrandStory');
    this.fetchingList = false;
  },
  computed: {
    brandStories() {
      return this.$store.getters['userFollowStore/brandStoriesByFollows'];
    },
    listFeedbackMessage() {
      if (this.fetchingList) {
        return '';
      }
      if (this.$store.getters['userFollowStore/followList'].length === 0) {
        return this.copy.messageFollowUser;
      }
      if (
        this.$store.getters['userFollowStore/brandStoriesByFollows'].length
        === 0
      ) {
        return this.copy.messageFollowingUsersNoPosts;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
