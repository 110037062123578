<template>
  <div class="userFollowList">
    <div class="title">TOP PEOPLE YOU FOLLOW</div>
    <LoadingOverlay v-show="showLoader" />
    <UserFollowItem v-for="(user, index) in this.followList"
      :key="'FollowedUser' + user.id"
      :user="user"
      :index="index"
      :showBtn="showBtn"
    />
  </div>
</template>

<script>
import UserFollowItem from './UserFollowItem.vue';
import LoadingOverlay from '../loaders/LoadingOverlay.vue';

export default {
  name: 'UserFollowListTop',
  components: {
    UserFollowItem,
    LoadingOverlay,
  },
  data() {
    return {
      showBtn: false,
      showLoader: false,
    };
  },
  async created() {
    if (this.followList.length === 0) {
      this.showLoader = true;
    }
    await this.$store.dispatch('userFollowStore/getFollowList');
    this.showLoader = false;
  },
  computed: {
    followList() {
      return this.$store.getters['userFollowStore/followList'];
    },
  },
};
</script>

<style lang="scss" scoped>
.userFollowList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5%;

  .title {
    font-weight: $bold;
    @include font-size(1.25rem);
    color: $LincolnGray;
    white-space: pre;
  }
}
.userFollowList > * {
  flex: 1 100%;
}
</style>
