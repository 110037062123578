<template>
  <div class="content-wrapper">
    <div class="pgTitle">My Community</div>
    <div class="community">
      <div class="groupItems">
        <BrandStoryListFollows class="sectionFollowedBrandStories" />
        <div class="sectionFollowUsers">
          <UserFollowListTop />
          <UserFollowListToFollow />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserFollowListTop from '../components/userFollowList/UserFollowListTop.vue';
import UserFollowListToFollow from '../components/userFollowList/UserFollowListToFollow.vue';
import BrandStoryListFollows from '../components/stories/brandStories/list/BrandStoriesListFollows.vue';

export default {
  name: 'Community',
  components: {
    UserFollowListTop,
    UserFollowListToFollow,
    BrandStoryListFollows,
  },
  async created() {
    this.$analytics.trackPageView();
  },
};
</script>

<style lang="scss" scoped>
.pgTitle {
  text-align: center;
  @include font-size(2.875rem);
  margin: 10px 0 20px 0;
}

.community {
  .groupItems {
    display: flex;
    flex-direction: column;

    @include respond(sm) {
      flex-direction: row;
    }

    .sectionFollowedBrandStories {
      flex: 3;

      @include respond(sm) {
        padding-left: 4%;
        padding-right: 4%;
      }
    }

    .sectionFollowUsers {
      flex: 1;

      @include respond(sm) {
        padding-left: 4%;
        padding-right: 4%;
      }
    }
  }
}
</style>
